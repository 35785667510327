import { useState } from 'react';
import { getCurrentMonth, getCurrentYear, MonthSelector } from './MonthSelector';
import { downloadDiscountExcelReport } from '../api/discount/discountReportCalls';

function DownloadButton({ year, month }: { year: number; month: number }) {
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <button
            type={'button'}
            key={isDownloading ? 'down' : 'ready'}
            className={`btn btn-default ${isDownloading ? 'btn-loading' : ''}`}
            disabled={isDownloading}
            onClick={() => downloadDiscountExcelReport(setIsDownloading, year, month)}
        >
            {!isDownloading && <span className='rioglyph rioglyph-cloud-download' aria-hidden='true' />}
            Export all usages and discounts of the month
        </button>
    );
}

export function UsageReportDownload() {
    const [filter, setFilter] = useState({ year: getCurrentYear(), month: getCurrentMonth() });
    return (
        <>
            <div className='margin-right-10'>
                <MonthSelector
                    month={filter.month}
                    year={filter.year}
                    selectYearAndMonth={(year, month) => setFilter({ year, month })}
                />
            </div>
            <div className='margin-right-10'>
                <DownloadButton year={filter.year} month={filter.month} />
            </div>
        </>
    );
}
