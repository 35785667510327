import { connect } from 'react-redux';
import { Navigate, NavLink, Route, Routes } from 'react-router';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { DiscountPage } from './discount/DiscountPage';
import { UtilitiesPage } from './utilities/UtilitiesPage';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice';
import type { RootState } from '../../configuration/setup/store';
import type { UserProfile } from 'oidc-client-ts';
import { UTILITIES_ROUTE, DISCOUNT_ROUTE, INTEGRATED_OFFER_ROUTE, ACTIVATIONS_ROUTE } from './routes/routes';
import { AppSubNavigationContainer } from './AppSubNavigationContainer';
import { IntegratedOfferPage } from './integrated-offer/IntegratedOfferPage';
import { ActivationsPage } from './activations/ActivationsPage';
import { initToggles } from './common/toggles/featureToggles';

export interface Props {
    idToken: UserProfile;
}

const iamHelpGangTab = {
    key: 'accounts',
    route: <a href={'https://helpgang.iam.rio.cloud/#/accounts'}>{'Accounts'}</a>,
};

const partnerIntegrationsHelpGangTab = {
    key: 'partner-integrations',
    route: <a href={'https://helpgang.iam.rio.cloud/#/partner-integrations'}>{'Partner Integrations'}</a>,
};

const clientMetricsHelpGangTab = {
    key: 'client-metrics',
    route: <a href={'https://helpgang.iam.rio.cloud/#/client-metrics'}>{'Client Metrics'}</a>,
};

const inboundConnectionsHelpGangTab = {
    key: 'inbound-connections',
    route: <a href={'https://helpgang.iam.rio.cloud/#/inbound-connections'}>{'Inbound Connections'}</a>,
};

const iotEventsHelpgangTab = {
    key: 'iot-events',
    route: <a href={'https://helpgang.assets.rio.cloud/#/iot-events'}>{'IoT Events'}</a>,
};

const assetsHelpGangTab = {
    key: 'assets',
    route: <a href={'https://helpgang.assets.rio.cloud'}>{'Assets'}</a>,
};

const deviceStateAssetsHelpGangTab = {
    key: 'device-state',
    route: <a href={'https://helpgang.assets.rio.cloud/#/device-state'}>{'Device State'}</a>,
};

const App = (props: Props) => {
    const { idToken } = props;

    const tabKeys = ['activation', 'discount'];

    if (!idToken?.sub?.startsWith('azure-my-rio-de')) {
        window.location.href = 'https://home.rio.cloud';
    }
    // Initialize toggles
    initToggles();
    return (
        <ApplicationLayout>
            <ApplicationLayout.Header>
                <ApplicationHeader
                    label={'Marketplace Admin'}
                    navItems={[
                        iamHelpGangTab,
                        partnerIntegrationsHelpGangTab,
                        clientMetricsHelpGangTab,
                        assetsHelpGangTab,
                        deviceStateAssetsHelpGangTab,
                        inboundConnectionsHelpGangTab,
                        ...tabKeys.map(createNavItem),
                        iotEventsHelpgangTab,
                    ]}
                    actionBarItems={[]}
                />
            </ApplicationLayout.Header>
            <ApplicationLayout.Body banner={<AppSubNavigationContainer />}>
                <NotificationsContainer />
                <Routes>
                    <Route path={ACTIVATIONS_ROUTE} element={<ActivationsPage />} />,
                    <Route path={INTEGRATED_OFFER_ROUTE} element={<IntegratedOfferPage />} />,
                    <Route path={DISCOUNT_ROUTE} element={<DiscountPage />} />
                    <Route path={UTILITIES_ROUTE} element={<UtilitiesPage />} />,
                    <Route path='*' element={<Navigate to={`/${ACTIVATIONS_ROUTE}`} replace />} />
                </Routes>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

const createNavItem = (key: string) => ({
    key,
    route: <NavLink to={`/${key}`}>{key.charAt(0).toUpperCase() + key.slice(1)}</NavLink>,
});

const mapStateToProps = (state: RootState): Props => ({
    // biome-ignore lint/style/noNonNullAssertion: legacy
    idToken: getIdToken(state)!,
});

export const AppContainer = connect(mapStateToProps)(App);
