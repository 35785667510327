import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getMarketplaceBackend } from '../../config/appConfig';
import type { AdminContract, AdminContractsEndDateUpdateRequest } from './adminTypes';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { mapAdminContractResponse } from './adminContractMapper';

export const contractApiSlice = createApi({
    reducerPath: 'contractApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getMarketplaceBackend(),
        prepareHeaders: headers => {
            const token = accessToken.getAccessToken();
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: builder => ({
        getContracts: builder.query<AdminContract[], { accountId: string }>({
            query: ({ accountId }) => `/admin/${accountId}/contracts`,
            transformResponse: (response: unknown) => mapAdminContractResponse(response),
        }),
        updateContractsEndDate: builder.mutation<
            null,
            { accountId: string; request: AdminContractsEndDateUpdateRequest }
        >({
            query: ({ accountId, request }) => ({
                url: `/admin/${accountId}/contracts`,
                method: 'PATCH',
                body: request,
            }),
        }),
    }),
});

export const { useGetContractsQuery, useLazyGetContractsQuery, useUpdateContractsEndDateMutation } = contractApiSlice;
