const PERIOD_REGEX = /^P(\d+)([MYD])$/;

const UNIT_LABELS: Record<string, string> = {
    Y: 'year',
    M: 'month',
    D: 'day',
};

export const formatPeriod = (period: string | undefined): string => {
    if (!period) {
        return '';
    }

    const match = period.match(PERIOD_REGEX);
    if (!match) {
        return period;
    }

    const [, amount, unit] = match;
    const value = Number.parseInt(amount, 10);
    const label = UNIT_LABELS[unit];

    return `${value} ${label}${value === 1 ? '' : 's'}`;
};
