import { useEffect, useMemo, useState } from 'react';
import { useLazyGetMergedActivationsQuery } from '../api/activation/mergedActivationApiSlice';
import { useTableFilters } from './hooks/useTableFilters';
import { TableFilters } from './components/TableFilters';
import { ActivationsTableContent } from './components/ActivationsTableContent';
import { filterData, sortData } from './utils';
import { products } from '../utilities/ProductSelect';
import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';
import type { MergedActivationInfo } from '../api/activation/types';
import { sortBy as _sortBy } from 'lodash';
import { nonInactiveStates } from '../api/activation/activation.types';

export const ActivationsTable = () => {
    const { filters, setFilters } = useTableFilters();
    const [getMerged, { isFetching, isError }] = useLazyGetMergedActivationsQuery();
    const [data, setData] = useState<MergedActivationInfo[] | undefined>(undefined);
    const [sortBy, setSortBy] = useState<string | null>('assetId');
    const [sortDir, setSortDir] = useState<SortDirectionType | null>(SortDirection.ASCENDING);
    const [secondarySortBy] = useState<string>('productName');

    useEffect(() => {
        fetchData();
    }, [filters.accountId, filters.showInactive, getMerged]);

    const visibleProducts = useMemo(
        () =>
            _sortBy(
                products.filter(product =>
                    data?.some(item => item.productId.toLowerCase() === product.id.toLowerCase())
                ),
                ['name', 'id']
            ),
        [data]
    );

    const fetchData = () => {
        if (filters.accountId?.trim()) {
            getMerged({
                accountId: filters.accountId.trim(),
                activationStates: filters.showInactive ? undefined : nonInactiveStates,
            })
                .then(it => setData(it.data))
                .catch(() => {
                    setData(undefined);
                });
        } else {
            setData(undefined);
        }
    };

    const filteredData = useMemo(() => filterData(data, filters), [data, filters]);

    const sortedData = useMemo(
        () => sortData(filteredData, sortBy, sortDir, secondarySortBy),
        [filteredData, sortBy, sortDir, secondarySortBy]
    );

    const handleSort = (column: string) => {
        if (sortBy === column) {
            if (sortDir === SortDirection.ASCENDING) {
                setSortDir(SortDirection.DESCENDING);
            } else if (sortDir === SortDirection.DESCENDING) {
                setSortDir(null);
                setSortBy(null);
            }
        } else {
            setSortBy(column);
            setSortDir(SortDirection.ASCENDING);
        }
    };

    return (
        <div className={'container-fluid fluid-default'} data-testid='activations-table'>
            <div className='table-toolbar'>
                <TableFilters filters={filters} setFilters={setFilters} visibleProducts={visibleProducts} />
            </div>
            <ActivationsTableContent
                isLoading={isFetching}
                isError={isError}
                filteredData={sortedData}
                sortBy={sortBy}
                sortDir={sortDir}
                onSort={handleSort}
                onReload={fetchData}
            />
        </div>
    );
};
