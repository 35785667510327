import { getRequest, putRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected, rejectIfNotOk } from '../api';
import { type ApiAdminDiscountCampaign, ApiAdminDiscountCampaignsCodec } from './discount.types';
import { mapApiCampaigns, mapCampaignToApi } from './discountMapper';
import { getMarketplaceBackend } from '../../config/appConfig';
import type { DiscountCampaign } from '../../discount/types';

export function fetchDiscountCampaigns(): Promise<DiscountCampaign[]> {
    return fetch(`${getMarketplaceBackend()}/admin/campaigns`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiAdminDiscountCampaignsCodec))
        .then(mapApiCampaigns)
        .catch(onRejected());
}

export function saveDiscountCampaign(campaign: DiscountCampaign): Promise<void> {
    const body = mapCampaignToApi(campaign);
    return fetch(
        `${getMarketplaceBackend()}/admin/campaigns/${campaign.id}`,
        putRequest<ApiAdminDiscountCampaign>(body)
    )
        .then(rejectIfNotOk)
        .catch(onRejected([400, 409]));
}
