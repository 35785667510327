import type { Dispatch } from 'redux';
import type { DiscountCampaign } from './types';
import { apiCallAction } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { fetchDiscountCampaigns } from '../api/discount/discountCampaignCalls';

export const fetchCampaignsThunk =
    () =>
    (dispatch: Dispatch): Promise<DiscountCampaign[]> => {
        dispatch(apiCallAction(ApiCalls.ADMIN_CAMPAIGNS, true, false));
        return fetchDiscountCampaigns()
            .then(response => {
                dispatch(apiCallAction(ApiCalls.ADMIN_CAMPAIGNS, false, false));
                return response;
            })
            .catch(() => {
                dispatch(apiCallAction(ApiCalls.ADMIN_CAMPAIGNS, false, true));
                return [];
            });
    };
