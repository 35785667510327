const convertToBool = (value: string | undefined): boolean => {
    return value?.toLowerCase() === 'true';
};

let activationsTabEnabled = false;
export const isActivationsTabEnabled = (): boolean => activationsTabEnabled;

interface QueryParams {
    enableActivationsTab?: string;
}

const parseQueryString = (queryString: string): QueryParams => {
    const params = new URLSearchParams(queryString);
    return {
        enableActivationsTab: params.get('enableActivationsTab') || undefined,
    };
};

export const initToggles = (): void => {
    const searchParams = parseQueryString(window.location.search);
};
