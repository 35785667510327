import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import moment from 'moment';

interface MonthSelectorProps {
    selectYearAndMonth: (year: number, month: number) => void;
    year: number;
    month: number;
}

export function getCurrentYear() {
    return new Date().getFullYear();
}

export function getCurrentMonth() {
    return new Date().getMonth() + 1;
}

export const MonthSelector = (props: MonthSelectorProps) => {
    const { selectYearAndMonth, year, month } = props;
    const month2Digits = `0${month}`.slice(-2);
    const currentYear = getCurrentYear();
    const currentMonth = getCurrentMonth();

    function getYearAndMonth(value: { format: (format: string) => string }) {
        return value.format('YYYY-MM').split('-').map(Number);
    }

    return (
        <DatePicker
            inputProps={{ readOnly: true, className: 'form-control cursor-pointer' }}
            dateFormat={'YYYY-MM'}
            className={'width-150'}
            timeFormat={false}
            value={`${year}-${month2Digits}`}
            onChange={value => {
                const [newYear, newMonth] = getYearAndMonth(moment(value));
                selectYearAndMonth(newYear, newMonth);
            }}
            isValidDate={(value: { format: (format: string) => string }) => {
                const [newYear, newMonth] = getYearAndMonth(value);
                return newYear < currentYear || (newYear === currentYear && newMonth <= currentMonth);
            }}
            closeOnSelect={true}
        />
    );
};
