import * as t from 'io-ts';

export interface ApiSubscribePayload {
    product_id: string;
    resource_ids: string[];
    level?: string;
}

export const API_ACTIVATION_STATE_ACTIVE = 'active';
export const API_ACTIVATION_STATE_INACTIVE = 'inactive';
export const API_ACTIVATION_STATE_PENDING_CANCELLATION = 'pending-cancellation';
export const API_ACTIVATION_STATE_PENDING_ACTIVATION = 'pending-activation';

export const ApiActivationStateCodec = t.union([
    t.literal(API_ACTIVATION_STATE_ACTIVE),
    t.literal(API_ACTIVATION_STATE_INACTIVE),
    t.literal(API_ACTIVATION_STATE_PENDING_CANCELLATION),
    t.literal(API_ACTIVATION_STATE_PENDING_ACTIVATION),
]);
export type ApiActivationState = t.TypeOf<typeof ApiActivationStateCodec>;

export const nonInactiveStates: ApiActivationState[] = [
    API_ACTIVATION_STATE_ACTIVE,
    API_ACTIVATION_STATE_PENDING_ACTIVATION,
    API_ACTIVATION_STATE_PENDING_CANCELLATION,
];

export const ApiResourceActivationCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        activation_state: ApiActivationStateCodec,
        product_id: t.string,
    }),
    t.partial({
        resource_name: t.string,
        origin_level: t.string,
        target_level: t.string,
    }),
]);

export const ApiResourceActivationsCodec = t.type({
    activations: t.array(ApiResourceActivationCodec),
});
export type ApiResourceActivations = t.TypeOf<typeof ApiResourceActivationsCodec>;

export interface ApiSubscriptionCancelPayload {
    resource_ids: string[];
    reason?: string;
}

export interface ActivationSummary {
    productId: string;
    amount: number;
}

const ApiActivationSummaryCodec = t.type({
    product_id: t.string,
    amount: t.number,
});

export const ApiActivationsSummaryCodec = t.type({
    activations: t.array(ApiActivationSummaryCodec),
});
export type ApiActivationsSummary = t.TypeOf<typeof ApiActivationsSummaryCodec>;

const ApiBookableResourceMissingFeatureCodec = t.intersection([
    t.type({
        feature_name: t.string,
        expected_values: t.array(t.string),
        inclusive: t.boolean,
    }),
    t.partial({
        actual: t.string,
    }),
]);

const ApiBookableResourceRequirementCodec = t.array(ApiBookableResourceMissingFeatureCodec);

const ApiBookableResourceCodec = t.intersection([
    t.type({
        resource_id: t.string,
        activation_state: ApiActivationStateCodec,
        bookable: t.boolean,
        resource_type: t.string,
    }),
    t.partial({
        resource_name: t.string,
        origin_level: t.string,
        target_level: t.string,
        missing_requirements: t.array(ApiBookableResourceRequirementCodec),
        has_open_contract: t.boolean,
    }),
]);

export const ApiBookableResourcesCodec = t.type({
    resources: t.array(ApiBookableResourceCodec),
});

export interface ResourceActivation {
    productId: string;
    resourceId: string;
    resourceType: string;
    resourceName: string;
    activationState: ApiActivationState;
    targetLevel?: string;
    originLevel?: string;
}

export type ApiBookableResources = t.TypeOf<typeof ApiBookableResourcesCodec>;
