import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getMarketplaceBackend } from '../../config/appConfig';
import type { PackageAdminCommand, PackageActivation } from './types';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { mapPackageActivationsResponse } from './packageActivationMapper';

export const packageActivationApiSlice = createApi({
    reducerPath: 'packageActivationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getMarketplaceBackend(),
        prepareHeaders: headers => {
            const token = accessToken.getAccessToken();
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: builder => ({
        getPackageActivations: builder.query<
            PackageActivation[],
            { accountId?: string; assetId?: string; status?: string }
        >({
            query: params => {
                const { accountId, assetId, status } = params;
                const queryParams = new URLSearchParams();

                if (accountId) {
                    queryParams.append('account_id', accountId);
                }
                if (assetId) {
                    queryParams.append('asset_id', assetId);
                }
                if (status) {
                    queryParams.append('status', status);
                }
                return `${getMarketplaceBackend()}/package-service/admin/package-activations${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
            },
            // biome-ignore lint/suspicious/noExplicitAny: deal with it
            transformResponse: (response: any) => mapPackageActivationsResponse(response),
        }),
        invokeCommand: builder.mutation<void, PackageAdminCommand>({
            query: ({ command, payload }) => ({
                url: `${getMarketplaceBackend()}/package-service/admin/${command}/invoke`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const { useGetPackageActivationsQuery, useLazyGetPackageActivationsQuery, useInvokeCommandMutation } =
    packageActivationApiSlice;
