import Spinner from '@rio-cloud/rio-uikit/Spinner';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useCallback, useEffect, useState } from 'react';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { connect } from 'react-redux';
import { fetchCampaignsThunk } from './campaigns.thunk';
import { CampaignRow } from './CampaignTableRow';
import type { DiscountCampaign } from './types';
import { CampaignRowEditable } from './CampaignTableRowEditable';
import { v4 as uuid } from 'uuid';
import type { FetchError } from '../api/FetchError';
import { saveDiscountCampaign } from '../api/discount/discountCampaignCalls';
import LoadingErrorState from '../common/LoadingErrorState';
import type { RootDispatch, RootState } from '../../../configuration/setup/store';

interface Props {
    isLoading: boolean;
    hasError: boolean;
    fetchCampaigns: () => Promise<DiscountCampaign[]>;
}

function Campaigns({ isLoading, hasError, fetchCampaigns }: Props) {
    const [campaigns, setCampaigns] = useState<DiscountCampaign[]>([]);
    const [newCampaign, setNewCampaign] = useState<DiscountCampaign | undefined>(undefined);
    const [editedCampaignId, setEditedCampaignId] = useState<string | undefined>();
    const reload = useCallback(() => {
        fetchCampaigns().then(setCampaigns);
    }, [fetchCampaigns, setCampaigns]);
    useEffect(() => {
        reload();
    }, [reload]);

    function saveAndReload(campaign: DiscountCampaign) {
        return saveDiscountCampaign(campaign)
            .then(() => {
                Notification.success(`Successfully saved campaign ${campaign.name}`);
                setEditedCampaignId(undefined);
                setNewCampaign(undefined);
            })
            .then(reload)
            .catch((error: FetchError) => {
                const message = error.problemJson?.detail
                    ? `Error during saving campaign: ${error.problemJson.detail}`
                    : 'Error during saving campaign';
                Notification.error(message);
                throw error;
            });
    }

    function createNewCampaign() {
        const id = uuid();
        setNewCampaign({ id, name: '' });
        setEditedCampaignId(id);
    }

    if (isLoading) {
        return <Spinner text={'Loading campaigns'} isInverse={false} />;
    }
    if (hasError) {
        return <LoadingErrorState />;
    }

    const isEditingCampaign = Boolean(newCampaign) || Boolean(editedCampaignId);

    const campaignsList = newCampaign ? [...campaigns, newCampaign] : campaigns;
    return (
        <>
            {campaignsList.length === 0 ? (
                <div className='alert alert-info'>No campaigns found</div>
            ) : (
                <table className='table table-head-filled table-layout-fixed'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Cost absorption</th>
                            <th>Starts at</th>
                            <th>Ends at</th>
                            <th>{''}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaignsList
                            .sort((c1, c2) => c1.name.localeCompare(c2.name))
                            .map(campaign =>
                                campaign.id === editedCampaignId ? (
                                    <CampaignRowEditable
                                        key={campaign.id}
                                        originalCampaign={campaign}
                                        save={saveAndReload}
                                        discard={() => {
                                            setEditedCampaignId(undefined);
                                            setNewCampaign(undefined);
                                        }}
                                    />
                                ) : (
                                    <CampaignRow
                                        key={campaign.id}
                                        campaign={campaign}
                                        edit={() => setEditedCampaignId(campaign.id)}
                                        editEnabled={Boolean(!editedCampaignId)}
                                    />
                                )
                            )}
                    </tbody>
                </table>
            )}
            <button
                type={'button'}
                className='btn btn-default'
                onClick={() => createNewCampaign()}
                disabled={isEditingCampaign}
            >
                <span className='rioglyph rioglyph-plus-sign' aria-hidden='true' />
                New campaign
            </button>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    isLoading: getIsLoading(state, ApiCalls.ADMIN_CAMPAIGNS),
    hasError: getHasError(state, ApiCalls.ADMIN_CAMPAIGNS),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchCampaigns: () => dispatch(fetchCampaignsThunk()),
});

export const CampaignsContainer = connect(mapStateToProps, mapDispatchToProps)(Campaigns);
