import type { MergedActivationInfo } from '../../api/activation/types';
import type { Filters } from '../types';

export const filterData = (
    data: MergedActivationInfo[] | undefined,
    filters: Filters
): MergedActivationInfo[] | undefined =>
    data?.filter(item => {
        if (filters.isOnlyContracts && !item.contractState) {
            return false;
        }
        if (filters.isOnlyPaid && !item.isPaid) {
            return false;
        }
        if (filters?.resourceIds && !filters.resourceIds.includes(item.assetId.toLowerCase())) {
            return false;
        }
        return !(filters?.productId && item.productId.toLowerCase() !== filters.productId.toLowerCase());
    });
