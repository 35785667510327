import type { ResourceActivation, BrowserInput } from './types';
import { subscribe } from '../api/activation/subscribeCall';

export async function activateResources(
    input: BrowserInput,
    setActivationResult: (value: ResourceActivation[]) => void,
    setIsLoading: (value: boolean) => void
) {
    setIsLoading(true);

    const activationResults: ResourceActivation[] = [];
    for (const item of input.items) {
        const { productId, accountId, resourceIds, level } = item;

        await subscribe(accountId, {
            product_id: productId,
            resource_ids: resourceIds,
            level,
        }).catch((error: Error) => {
            activationResults.push({
                accountId,
                productId,
                level,
                resourceId: item.resourceIds.toString(),
                error: error.message,
            });
        });
    }

    setActivationResult(activationResults);
    setIsLoading(false);
}
