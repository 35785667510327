import { isRight } from 'fp-ts/lib/Either';
import { ApiBookableResourcesCodec } from './activation.types';
import type { BookableInfo } from '../../utilities/types';
import { convertApiActivationState } from './convertApiActivationState';

export class BookableResourceError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'BookableResourceError';
    }
}

export function mapBookableResponse(apiResponse: unknown): BookableInfo[] {
    const decodedResponse = ApiBookableResourcesCodec.decode(apiResponse);
    if (isRight(decodedResponse)) {
        return decodedResponse.right.resources.map(resource => ({
            resourceId: resource.resource_id,
            bookable: resource.bookable,
            activationState: convertApiActivationState(resource.activation_state),
            resourceType: resource.resource_type,
            resourceName: resource.resource_name,
            originLevel: resource.origin_level,
            targetLevel: resource.target_level,
            missingRequirements: resource.missing_requirements?.map(requirement =>
                requirement.map(missingFeature => ({
                    featureName: missingFeature.feature_name,
                    actual: missingFeature.actual,
                    expectedValues: missingFeature.expected_values,
                    inclusive: missingFeature.inclusive,
                }))
            ),
            hasOpenContract: resource.has_open_contract,
        }));
    }

    throw new BookableResourceError(`Invalid bookable resource response ${JSON.stringify(apiResponse)}`);
}
