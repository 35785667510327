import {
    configureMockUserManager,
    configureUserManager,
    createUserManager,
    type SessionRenewedResult,
} from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { store } from './setup/store';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import type { User, UserManager } from 'oidc-client-ts';
import { reportErrorToSentry } from './setup/sentry';

export interface OAuthConfig {
    onSessionExpired: () => void;
    onSessionRenewed: (arg: SessionRenewedResult) => void;
}

export const main = async (renderApp: (arg: { user: User; logout: () => void }) => void) => {
    const oauthConfig: OAuthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());
        },
    };

    const isAllowedToMockAuth = import.meta.env.DEV;
    const userManager: UserManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    try {
        await userManager.clearStaleState();
        const user = await attemptInitialSignIn(userManager);
        // biome-ignore lint/style/noNonNullAssertion: legacy
        renderApp({ user: user!, logout: () => {} });
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
