import { fileUpload, getRequest } from '../requests';
import { blobOrReject, convertContentToBlob, decodeJson, sendErrorIfItShouldNotBeIgnored } from '../api';
import { ApiDiscountUploadProblemJsonCodec, type DiscountType } from './discount.types';
import FileSaver from 'file-saver';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { XLSX_TYPE } from '../../discount/fileTypes';
import { getMarketplaceBackend } from '../../config/appConfig';
import { getCurrentDate } from './dateUtil';
import { upperFirst } from 'lodash';

function displayUploadErrorOrDownloadExcel(response: Response, nameOfUploadedFile: string) {
    return response
        .json()
        .then(decodeJson(ApiDiscountUploadProblemJsonCodec))
        .then(problemJson => {
            Notification.error(`${problemJson.title}\n${problemJson.detail}`);
            if (problemJson.validation_file) {
                const file = convertContentToBlob(problemJson.validation_file, XLSX_TYPE);
                FileSaver.saveAs(file, nameOfUploadedFile.replace('.xlsx', '-error.xlsx'));
            }
        });
}

export function uploadDiscountExcel(
    discountExcel: File,
    setIsUploading: (value: boolean) => void,
    discountType: DiscountType
) {
    setIsUploading(true);
    return fetch(`${getMarketplaceBackend()}/admin/discounts?discountType=${discountType}`, fileUpload(discountExcel))
        .then(response => {
            if (response.ok) {
                Notification.success('Discount excel successfully uploaded');
                return Promise.resolve();
            }
            return displayUploadErrorOrDownloadExcel(response, discountExcel.name);
        })
        .catch(error => {
            sendErrorIfItShouldNotBeIgnored(error, [401]);
            const hint = 'Try a smaller excel if it has more than 10000 lines';
            Notification.error(`Error while uploading the discount excel:\n${error.message}\n${hint}`);
        })
        .finally(() => setIsUploading(false));
}

export function downloadDiscountExcel(setIsDownloading: (isSet: boolean) => void, discountType: DiscountType) {
    setIsDownloading(true);
    return fetch(`${getMarketplaceBackend()}/admin/discounts?discountType=${discountType}`, getRequest())
        .then(blobOrReject)
        .then(response =>
            FileSaver.saveAs(
                response,
                `RIO-${upperFirst(discountType)}-Discounts-${getCurrentDate().toISOString()}.xlsx`
            )
        )
        .catch(error => {
            sendErrorIfItShouldNotBeIgnored(error, [401]);
            Notification.error('Error while downloading the discount excel');
        })
        .finally(() => setIsDownloading(false));
}
