import * as t from 'io-ts';

export const PackageActivationResponseCodec = t.type({
    package_activations: t.array(
        t.type({
            id: t.string,
            account_id: t.string,
            asset_id: t.string,
            package_code: t.string,
            status: t.union([
                t.literal('QUEUED'),
                t.literal('CREATED'),
                t.literal('FAILED'),
                t.literal('SUCCEEDED'),
                t.literal('REJECTED'),
                t.literal('CANCELLED'),
            ]),
            booking_intent_operation_number: t.string,
            last_updated_at: t.string,
            starts_at: t.union([t.string, t.undefined]),
            ends_at: t.union([t.string, t.undefined]),
            succeeded_at: t.union([t.string, t.undefined]),
        })
    ),
});
export type PackageActivationResponse = t.TypeOf<typeof PackageActivationResponseCodec>;
