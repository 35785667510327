import type { ResourceActivation, BrowserInput } from './types';
import { deactivateResourcesForProduct } from '../api/activation/deactivationCall';

export async function deactivateResources(
    input: BrowserInput,
    setDeactivationResult: (value: ResourceActivation[]) => void,
    setIsLoading: (value: boolean) => void,
    isDeactivateAllResources: boolean
) {
    setIsLoading(true);
    const deactivationResults: ResourceActivation[] = [];

    for (const item of input.items) {
        const { accountId, productId } = item;
        const resourceIds = isDeactivateAllResources ? undefined : item.resourceIds;

        await deactivateResourcesForProduct(accountId, productId, resourceIds).catch((error: Error) =>
            deactivationResults.push({ accountId, productId, error: error.message })
        );
    }

    setDeactivationResult(deactivationResults);
    setIsLoading(false);
}
