import type { ApiResourceActivations, ResourceActivation, ApiActivationState } from './activation.types';

export function mapResourceActivations(apiResponse: ApiResourceActivations): ResourceActivation[] {
    return apiResponse.activations.map(it => ({
        productId: it.product_id,
        resourceId: it.resource_id,
        resourceType: it.resource_type,
        resourceName: it.resource_name ?? '',
        activationState: it.activation_state as ApiActivationState,
        targetLevel: it.target_level,
        originLevel: it.origin_level,
    }));
}
