import { products } from '../../utilities/ProductSelect';
import type { ResourceActivation } from './activation.types';
import type { Contract } from '../contract/types';
import type { MergedActivationInfo } from './types';

const freeProductLevels: [string, string[]][] = [
    ['man-sku00000004', ['20.beta']],
    ['man-sku00000026', []],
    ['man-sku00000049', ['10', '30.beta']],
    ['man-sku00000050', ['10']],
    ['man-sku00000060', ['10']],
    ['man-sku00000060', ['20']],
    ['man-sku00000072', ['10']],
    ['man-sku00000076', ['10']],
    ['man-sku00000076', ['11']],
    ['man-sku00000077', ['10']],
    ['man-sku00000081', ['10']],
];
const isFree = (activation: ResourceActivation): boolean => {
    if (activation.activationState === 'inactive') {
        return true;
    }
    if (['active', 'pending-activation'].includes(activation.activationState)) {
        return freeProductLevels.some(
            ([id, levels]) =>
                id === activation.productId.toLowerCase() &&
                (levels.length === 0 || (activation.targetLevel && levels.includes(activation.targetLevel)))
        );
    }

    return freeProductLevels.some(
        ([id, levels]) =>
            id === activation.productId.toLowerCase() &&
            (levels.length === 0 || (activation.originLevel && levels.includes(activation.originLevel)))
    );
};

export function mapMergedActivations(
    accountId: string,
    activations: ResourceActivation[],
    contracts: Contract[]
): MergedActivationInfo[] {
    const productMap = new Map(products.map(p => [p.id.toLowerCase(), p.name]));

    const contractsById = new Map<string, Contract>();
    contracts.forEach(contract => {
        contractsById.set(getContractId(contract), contract);
    });

    const mergedActivations: MergedActivationInfo[] = [];
    const contractIdsWithActivations = new Set<string>();

    activations.forEach(activation => {
        const activationId = getActivationStateId(activation);
        const contract = contractsById.get(activationId);
        if (contract) {
            contractIdsWithActivations.add(activationId);
        }
        const productId = activation.productId.toLowerCase();

        if (contract) {
            const productName = productMap.get(productId) || contract.productName || productId;
            mergedActivations.push({
                accountId,
                assetId: activation.resourceId,
                productId: activation.productId,
                productName,
                level: contract.level,
                activationState: activation.activationState,
                ...mapContract(contract),
            });
        } else {
            const productName = productMap.get(productId) || productId;
            mergedActivations.push({
                accountId,
                assetId: activation.resourceId,
                productId: activation.productId,
                productName,
                level: ['active', 'pending-activation'].includes(activation.activationState)
                    ? activation.targetLevel
                    : activation.originLevel,
                activationState: activation.activationState,
                isPaid: !isFree(activation),
            });
        }
    });

    contracts
        .filter(contract => !contractIdsWithActivations.has(getContractId(contract)))
        .forEach(contract => {
            const productName = productMap.get(contract.productId.toLowerCase()) || contract.productId.toLowerCase();
            mergedActivations.push({
                accountId,
                assetId: contract.resourceId,
                productId: contract.productId,
                productName,
                level: contract.level,
                activationState: 'inactive',
                ...mapContract(contract),
            });
        });

    return mergedActivations;
}

function mapContract(contract: Contract) {
    return {
        contractState: contract.state,
        contractPeriod: contract.period,
        contractStarts: contract.startsAt,
        contractEnds: contract.endsAt,
        contractRenewal: Boolean(contract.renewsTo),
        renewsTo: contract.renewsTo
            ? {
                  level: contract.renewsTo.level,
                  productLevelName: contract.renewsTo.productLevelName,
                  productVariantName: contract.renewsTo.productVariantName,
                  period: contract.renewsTo.period,
              }
            : undefined,
        isPaid: Boolean(contract.paid),
    };
}

function getContractId(contract: Contract): string {
    return `${contract.productId}_${contract.resourceId}`;
}

function getActivationStateId(activationState: ResourceActivation): string {
    return `${activationState.productId}_${activationState.resourceId}`;
}
