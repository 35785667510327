import type { MergedActivationInfo } from '../../api/activation/types';
import { formatPeriod } from './formatPeriod';

export const formatRenewalInfo = (item: MergedActivationInfo) => {
    if (item.contractRenewal === false) {
        return (
            <span className='display-flex align-items-center'>
                <span className='rioglyph rioglyph-remove text-color-danger margin-right-3' />
                <span>Does not renew</span>
            </span>
        );
    }

    if (item.renewsTo) {
        const period = formatPeriod(item.renewsTo.period);
        const productLevelName = item.renewsTo.productLevelName;
        const productLevelNameContainsPeriod = new RegExp(period, 'i').test(productLevelName);
        const displayText = productLevelNameContainsPeriod ? productLevelName : `${productLevelName} (${period})`;

        return (
            <span className='display-flex align-items-center'>
                <span className='rioglyph rioglyph-repeat text-color-success margin-right-3' />
                <span>{displayText}</span>
            </span>
        );
    }

    return null;
};
