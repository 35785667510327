import type React from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/components/dropdown/ButtonDropdown';

type BatchButtonProps = {
    checkedRowIds: string[];
    onSelectAll: () => void;
    onDeselectAll: () => void;
    handleBulkCancel: () => void;
    handleBulkContractEditing: () => void;
};

const BatchButton: React.FC<BatchButtonProps> = ({
    checkedRowIds,
    onSelectAll,
    onDeselectAll,
    handleBulkCancel,
    handleBulkContractEditing,
}) => {
    return (
        <ButtonDropdown
            dropdownClassName={'overflow-auto'}
            title={<span className='rioglyph rioglyph rioglyph-checkboxes' />}
            className='btn-s'
            variant='link'
            iconOnly
            items={[
                {
                    value: <div>Select All</div>,
                    onSelect: () => onSelectAll(),
                },
                {
                    value: <div>Deselect All</div>,
                    onSelect: () => onDeselectAll(),
                },
                {
                    divider: true,
                },
                {
                    value: (
                        <div>
                            <span className='rioglyph rioglyph-remove-circle margin-right-10 text-size-18' />
                            Cancel Selected
                        </div>
                    ),
                    onSelect: handleBulkCancel,
                    disabled: checkedRowIds.length === 0,
                },
                {
                    value: (
                        <div>
                            <span className='rioglyph rioglyph-calendar margin-right-10 text-size-18' />
                            End contract on specific date
                        </div>
                    ),
                    onSelect: handleBulkContractEditing,
                    disabled: checkedRowIds.length === 0,
                },
            ]}
        />
    );
};

export default BatchButton;
