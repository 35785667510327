import { isRight } from 'fp-ts/lib/Either';
import { AdminContractsResponseCodec } from './adminContractCodec';
import type { AdminContract } from './adminTypes';
import { mapContractResponse } from './contractMapper';

export class AdminContractMapperError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AdminContractMapperError';
    }
}

export function mapAdminContractResponse(response: unknown): AdminContract[] {
    const decoded = AdminContractsResponseCodec.decode(response);
    if (!isRight(decoded)) {
        throw new AdminContractMapperError(`Invalid admin contract response: ${JSON.stringify(response)}`);
    }

    return mapContractResponse({ contracts: decoded.right.contracts });
}
