import { useState } from 'react';
import type { BrowserInput, ResourceActivation } from './types';
import { DisplayValidationError } from './DisplayValidationErrors';
import { activateResources } from './activationService';

export const ActivationSection = (props: { input: BrowserInput }) => {
    const { input } = props;
    const [activationResult, setActivationResult] = useState<ResourceActivation[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div>
            <button
                type={'button'}
                className={'btn btn-primary margin-20'}
                onClick={() => activateResources(input, setActivationResult, setIsLoading)}
            >
                Activate resources
            </button>
            <DisplayValidationError
                validationResult={activationResult}
                isLoading={isLoading}
                expanderText={'Validation result for activation'}
                loadingText={'Activating the resources...'}
                startText={'No activation triggered yet'}
                noErrorText={'All activations have been successfully sent.'}
            />
        </div>
    );
};
