import type React from 'react';
import { useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import type { Moment } from 'moment';
import type { MergedActivationInfo } from '../../api/activation/types';
import { formatDate } from '../utils';
import { useUpdateContractsEndDateMutation, useGetContractsQuery } from '../../api/contract/contractApiSlice';
import { Notification } from '@rio-cloud/rio-uikit';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';

type ContractEndDateEditingDialogProps = {
    data: MergedActivationInfo[];
    show: boolean;
    onClickCancel: () => void;
    onClickConfirm: () => void;
    onSuccess: () => void;
};

const ContractEndDateEditingDialog: React.FC<ContractEndDateEditingDialogProps> = ({
    data,
    show,
    onClickCancel,
    onClickConfirm,
    onSuccess,
}) => {
    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [updateContracts] = useUpdateContractsEndDateMutation();
    const { refetch } = useGetContractsQuery(
        { accountId: data[0]?.accountId || '' },
        {
            skip: !data.length,
        }
    );

    const handleDateChange = (value: string | Moment, isValid: boolean) => {
        if (isValid) {
            setSelectedDate(value as Moment);
        }
    };

    const handleConfirm = async () => {
        if (!selectedDate || !data.length) {
            return;
        }

        const accountId = data[0].accountId;
        const requestData = data.map(item => ({
            ends_at: selectedDate.format('YYYY-MM-DD'),
            product_id: item.productId,
            resource_id: item.assetId,
        }));

        try {
            await updateContracts({
                accountId,
                request: { contracts: requestData },
            });
            await refetch();
            Notification.success('Successfully updated contracts end date!');
            onSuccess();
            onClickConfirm();
        } catch (e) {
            Notification.error('Error updating contracts end date!');
            reportErrorToSentry(e);
        }
    };

    const content = (
        <div>
            <div className='row margin-bottom-10'>
                <div className='col-12 col-sm-4' data-testid={'contractEndDatePicker'}>
                    <label htmlFor='2' className='display-flex flex-wrap justify-content-between'>
                        <label>New end date for the selected contract(s)</label>
                    </label>
                    <DatePicker
                        inputProps={{ placeholder: 'Select date' }}
                        dateFormat='DD/MM/YYYY'
                        timeFormat={false}
                        onChange={handleDateChange}
                        isValidDate={(current: Moment) => current.isSameOrAfter(new Date(), 'day')}
                    />
                </div>
            </div>
            <div className='row margin-bottom-10'>
                <div className={'container-fluid fluid-default'} data-testid='contractenddateediting-table'>
                    <div className='table-responsive min-height-200'>
                        <table className='table table-hover table-head-filled table-bordered'>
                            <thead>
                                <tr>
                                    {COLUMNS.map(column => (
                                        <th key={column.id}>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data
                                    .filter(item => item.contractEnds)
                                    .map(item => (
                                        <tr key={`dialog-${item.accountId}-${item.assetId}-${item.productId}`}>
                                            <td>{item.accountId}</td>
                                            <td>{item.assetId}</td>
                                            <td>{item.productName}</td>
                                            <td>{formatDate(item.contractEnds)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={show}
            title={'End contract on specific date'}
            content={content}
            cancelButtonText='Abort'
            confirmButtonText={
                <>
                    <span className='rioglyph rioglyph-save margin-right-5' />
                    <span>Save change</span>
                </>
            }
            onClickCancel={onClickCancel}
            onClickConfirm={handleConfirm}
            disableConfirm={!selectedDate}
        />
    );
};

const COLUMNS = [
    { id: 'accountId', label: 'Account ID' },
    { id: 'assetId', label: 'Asset ID' },
    { id: 'productName', label: 'Product' },
    { id: 'currentEndDate', label: 'Current end date' },
];

export default ContractEndDateEditingDialog;
