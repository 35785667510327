import type { ResourceActivation } from '../types';
import { DisplayActivationStates } from '../DisplayActivationStates';
import { DisplayValidationError } from '../DisplayValidationErrors';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

export function ResetResultSection(props: {
    afterDeactivation: ResourceActivation[] | undefined;
    beforeDeactivation: ResourceActivation[] | undefined;
    activationResult: ResourceActivation[] | undefined;
    afterActivation: ResourceActivation[] | undefined;
    resetting: boolean;
}) {
    return (
        <>
            {props.afterDeactivation && <h4 className={'padding-10'}>Result</h4>}
            {props.beforeDeactivation && (
                <DisplayActivationStates
                    expanderText={'Before Deactivation'}
                    noItemsText={'No resources deactivated'}
                    loadingText={'Loading'}
                    isLoading={false}
                    validationResult={props.beforeDeactivation}
                />
            )}
            {props.afterDeactivation && (
                <DisplayActivationStates
                    expanderText={'Deactivation'}
                    noItemsText={'No resources deactivated'}
                    loadingText={'Deactivating'}
                    isLoading={false}
                    validationResult={props.afterDeactivation}
                />
            )}
            {props.activationResult && (
                <DisplayValidationError
                    startText={''}
                    expanderText={'Activation Result'}
                    noErrorText={'No error while activating resources again'}
                    loadingText={'Activating'}
                    isLoading={false}
                    validationResult={props.activationResult}
                />
            )}
            {props.afterActivation && (
                <DisplayActivationStates
                    expanderText={'State after activation'}
                    noItemsText={'No resources activated'}
                    loadingText={'Activating'}
                    isLoading={false}
                    validationResult={props.afterActivation}
                />
            )}
            {props.resetting && <Spinner text={'Resetting activations'} />}
        </>
    );
}
