import * as t from 'io-ts';
import { type ApiActivationState, ApiActivationStateCodec } from '../activation/activation.types';

export const ContractStateCodec = t.union([t.literal('active'), t.literal('inactive'), t.literal('pending')]);

export type ContractState = t.TypeOf<typeof ContractStateCodec>;

const RenewsToCodec = t.intersection([
    t.type({
        level: t.string,
        product_level_name: t.string,
    }),
    t.partial({
        product_variant_name: t.string,
        period: t.string,
    }),
]);

export const ApiContractCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        product_id: t.string,
        level: t.string,
        state: ContractStateCodec,
        activation_state: ApiActivationStateCodec,
        period: t.string,
        product_name: t.string,
        product_level_name: t.string,
        product_variant_name: t.string,
        paid: t.boolean,
    }),
    t.partial({
        resource_name: t.string,
        starts_at: t.string,
        ends_at: t.string,
        renews_to: RenewsToCodec,
    }),
]);

export const ApiContractsCodec = t.type({
    contracts: t.array(ApiContractCodec),
});

export interface Contract {
    resourceId: string;
    resourceType: string;
    productId: string;
    level: string;
    state: ContractState;
    activationState: ApiActivationState;
    period: string;
    productName: string;
    productLevelName: string;
    productVariantName: string;
    paid: boolean;
    resourceName?: string;
    startsAt?: string;
    endsAt?: string;
    renewsTo?: {
        level: string;
        productLevelName: string;
        productVariantName?: string;
        period?: string;
    };
}

export type ApiContractsResponse = t.TypeOf<typeof ApiContractsCodec>;
