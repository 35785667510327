import type { DiscountCampaign } from './types';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

interface Props {
    originalCampaign: DiscountCampaign;
    save: (campaign: DiscountCampaign) => Promise<void>;
    discard: () => void;
}

export function CampaignRowEditable({ originalCampaign, save, discard }: Props) {
    const [campaign, setCampaign] = useState<DiscountCampaign>(cloneDeep(originalCampaign));
    const [saveInProgress, setSaveInProgress] = useState(false);

    const saveDisabled = saveInProgress || !campaign.name;

    function getDatePicker(dateProperty: 'endsAt' | 'startsAt') {
        return (
            <>
                {campaign[dateProperty] !== undefined ? (
                    <DatePicker
                        inputProps={{ readOnly: true, className: 'form-control cursor-pointer' }}
                        dateFormat={'YYYY-MM-DD'}
                        timeFormat={false}
                        value={campaign[dateProperty]}
                        onChange={value =>
                            setCampaign({ ...campaign, [dateProperty]: moment(value).format('YYYY-MM-DD') })
                        }
                        closeOnSelect={true}
                    />
                ) : (
                    <button
                        type={'button'}
                        className='btn btn-link margin-0 padding-0'
                        onClick={() =>
                            setCampaign({ ...campaign, [dateProperty]: new Date().toISOString().substring(0, 10) })
                        }
                    >
                        Select date
                    </button>
                )}
            </>
        );
    }

    return (
        <tr>
            <td>
                <ClearableInput
                    value={campaign.name}
                    onChange={(value: string) => setCampaign({ ...campaign, name: value.trim() })}
                    placeholder='Insert campaign name'
                />
            </td>
            <td>
                <Checkbox
                    checked={Boolean(campaign.costAbsorption)}
                    onClick={() => setCampaign({ ...campaign, costAbsorption: !campaign.costAbsorption })}
                />
            </td>
            <td>{getDatePicker('startsAt')}</td>
            <td>{getDatePicker('endsAt')}</td>
            <td>
                <button
                    type={'button'}
                    className='btn btn-primary'
                    disabled={saveDisabled}
                    onClick={() => {
                        setSaveInProgress(true);
                        save(campaign).catch(() => setSaveInProgress(false));
                    }}
                >
                    <span className='rioglyph rioglyph-save' aria-hidden='true' />
                    Save
                </button>
                <button type={'button'} className='btn btn-default margin-left-10' onClick={discard}>
                    Discard changes
                </button>
            </td>
        </tr>
    );
}
