import * as t from 'io-ts';

export enum DiscountType {
    ASSET = 'asset',
    PRODUCT = 'product',
    ACCOUNT = 'account',
}

export const ApiAdminDiscountCampaignCodec = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
    }),
    t.partial({
        cost_absorption: t.boolean,
        starts_at: t.string,
        ends_at: t.string,
    }),
]);
export type ApiAdminDiscountCampaign = t.TypeOf<typeof ApiAdminDiscountCampaignCodec>;

export const ApiAdminDiscountCampaignsCodec = t.type({
    campaigns: t.array(ApiAdminDiscountCampaignCodec),
});
export type ApiAdminDiscountCampaigns = t.TypeOf<typeof ApiAdminDiscountCampaignsCodec>;

export const ApiDiscountUploadProblemJsonCodec = t.intersection([
    t.type({
        detail: t.string,
        status: t.number,
        title: t.string,
    }),
    t.partial({
        validation_file: t.string,
    }),
]);
export type ApiDiscountUploadProblemJson = t.TypeOf<typeof ApiDiscountUploadProblemJsonCodec>;
