import type React from 'react';
import { useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import ClearableInput from '@rio-cloud/rio-uikit/components/clearableInput/ClearableInput';
import type { Moment } from 'moment';
import { useCreateBookingIntentsCommand } from './useCreateBookingIntentsCommand';
import moment from 'moment';

type BookingIntentCreationDialogProps = {
    show: boolean;
    onClickCancel: () => void;
    onClickConfirm: () => void;
};

const validateVin = (vin: string): boolean => {
    return /^[A-Za-z0-9,-]+$/.test(vin);
};

const validateCountryCode = (code: string): boolean => {
    return /^[A-Z]{2}$/.test(code);
};

const BookingIntentCreationDialog: React.FC<BookingIntentCreationDialogProps> = ({
    show,
    onClickCancel,
    onClickConfirm,
}) => {
    const [vins, setVins] = useState('');
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [countryCode, setCountryCode] = useState('');
    const [operationPrefix, setOperationPrefix] = useState('');
    const [validVinCount, setValidVinCount] = useState(0);
    const createBookingIntents = useCreateBookingIntentsCommand();

    const handleDateChange = (value: string | Moment, isValid: boolean) => {
        if (isValid) {
            setStartDate(value as Moment);
        } else {
            setStartDate(undefined);
        }
    };

    const handleVinsChange = (value: string) => {
        setVins(value);
        const vinList = value
            .split(',')
            .map(v => v.trim())
            .filter(Boolean);
        const allValid = vinList.every(validateVin);
        setValidVinCount(allValid ? vinList.length : 0);
    };

    const handleCountryCodeChange = (value: string) => {
        setCountryCode(value.toUpperCase());
    };

    const handleConfirm = async () => {
        if (!startDate || !vins.trim() || !countryCode.trim()) {
            return;
        }

        const vinList = vins
            .split(',')
            .map(vin => vin.trim())
            .filter(Boolean);

        try {
            await createBookingIntents({
                vins: vinList,
                startDate: startDate.format('YYYY-MM-DD'),
                country: countryCode,
                operationNumberPrefix: operationPrefix,
            });

            onClickConfirm();
            resetForm();
        } catch (e) {
            console.error('Error in handleConfirm:', e);
        }
    };

    const resetForm = () => {
        setVins('');
        setStartDate(undefined);
        setCountryCode('');
        setOperationPrefix('');
        setValidVinCount(0);
    };

    const isVinsValid =
        vins.trim() !== '' &&
        vins
            .split(',')
            .map(v => v.trim())
            .filter(Boolean)
            .every(validateVin);

    const isFormValid = isVinsValid && startDate !== undefined && validateCountryCode(countryCode);

    const content = (
        <div className='row'>
            <div className='col-12'>
                <div className={`form-group ${!isVinsValid && vins ? 'has-error has-feedback' : ''}`}>
                    <label htmlFor='vins'>VINs (comma-separated)</label>
                    <div className='input-group'>
                        <ClearableInput
                            id='vins'
                            value={vins}
                            onChange={handleVinsChange}
                            placeholder='Enter VINs separated by commas'
                        />
                        {!isVinsValid && vins && (
                            <span className='form-control-feedback rioglyph rioglyph-error-sign' aria-hidden='true' />
                        )}
                    </div>
                    {!isVinsValid && vins && (
                        <span className='help-block'>VINs can only contain letters, numbers, and hyphens</span>
                    )}
                </div>

                <div className='form-group margin-bottom-10'>
                    <label>Start Date</label>
                    <DatePicker
                        inputProps={{ placeholder: 'Select date' }}
                        dateFormat='DD/MM/YYYY'
                        timeFormat={false}
                        value={startDate}
                        onChange={handleDateChange}
                        isValidDate={(current: Moment) => current.isSameOrBefore(moment(), 'day')}
                    />
                </div>

                <div
                    className={`form-group ${countryCode && !validateCountryCode(countryCode) ? 'has-error has-feedback' : ''}`}
                >
                    <label htmlFor='countryCode'>Country Code (2 letters)</label>
                    <div className='input-group'>
                        <ClearableInput
                            id='countryCode'
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            placeholder='Enter country code (e.g. DE)'
                            maxLength={2}
                        />
                        {countryCode && !validateCountryCode(countryCode) && (
                            <span className='form-control-feedback rioglyph rioglyph-error-sign' aria-hidden='true' />
                        )}
                    </div>
                    {countryCode && !validateCountryCode(countryCode) && (
                        <span className='help-block'>Country code must be exactly 2 uppercase letters</span>
                    )}
                </div>

                <div className='form-group margin-bottom-10'>
                    <label htmlFor='operationPrefix'>Operation Number Prefix</label>
                    <ClearableInput
                        id='operationPrefix'
                        value={operationPrefix}
                        onChange={setOperationPrefix}
                        placeholder='Enter operation number prefix'
                    />
                </div>
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={show}
            title='Create Booking Intent'
            content={content}
            cancelButtonText='Cancel'
            confirmButtonText={
                <>
                    <span className='rioglyph rioglyph-save margin-right-5' />
                    <span>Create ({validVinCount})</span>
                </>
            }
            onClickCancel={() => {
                onClickCancel();
                resetForm();
            }}
            onClickConfirm={handleConfirm}
            disableConfirm={!isFormValid}
        />
    );
};

export default BookingIntentCreationDialog;
