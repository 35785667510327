type LabelStyle = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'muted';

const activationStateMap: Record<string, LabelStyle> = {
    active: 'success',
    inactive: 'muted',
    'pending-activation': 'warning',
    'pending-cancellation': 'danger',
};

const contractStateMap: Record<string, LabelStyle> = {
    active: 'success',
    inactive: 'muted',
    pending: 'warning',
};

export const formatActivationState = (state: string | undefined) => {
    if (!state) {
        return null;
    }
    const labelStyle = activationStateMap[state] || 'default';
    return <div className={`label label-${labelStyle} label-filled label-condensed`}>{state.replace('-', ' ')}</div>;
};

export const formatContractState = (state: string | undefined) => {
    if (!state) {
        return null;
    }
    const labelStyle = contractStateMap[state] || 'default';
    return <div className={`label label-${labelStyle} label-filled label-condensed`}>{state}</div>;
};
