import { ActivationInfosSpec, type BrowserInput, type ResourceActivation } from './types';
import { decodeJson } from '../api/api';

export const FALLBACK_BROWSER_INPUT = { items: [], error: 'No "input" variable defined at window' };

export function readAndValidationInputFromBrowser(): BrowserInput {
    try {
        // @ts-ignore
        const inputFromBrowserVariable = window.input;
        if (inputFromBrowserVariable === undefined) {
            return FALLBACK_BROWSER_INPUT;
        }
        return { items: decodeJson(ActivationInfosSpec)(inputFromBrowserVariable) };
        // biome-ignore lint/suspicious/noExplicitAny: legacy
    } catch (e: any) {
        return { items: [], error: e.message };
    }
}

export function mapResourceActivations(resourceActivations: ResourceActivation[] | undefined): BrowserInput {
    if (!resourceActivations || resourceActivations.length === 0) {
        return { items: [] };
    }

    return {
        items: resourceActivations.map(resourceActivation => ({
            accountId: resourceActivation.accountId,
            resourceIds: [resourceActivation.resourceId as string],
            productId: resourceActivation.productId,
            level: resourceActivation.level,
        })),
    };
}
