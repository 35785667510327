import type { MergedActivationInfo } from '../api/activation/types';
import type { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

export type Filters = {
    accountId?: string;
    resourceIds?: string[];
    productId?: string;
    isOnlyContracts: boolean;
    isOnlyPaid: boolean;
    showInactive: boolean;
};

export type SortDirection = SortDirectionType | null;

export interface ActivationsTableContentProps {
    isLoading: boolean;
    isError: boolean;
    filteredData: MergedActivationInfo[] | undefined;
    sortBy: string | null;
    sortDir: SortDirection;
    onSort: (column: string) => void;
    onReload: () => void;
}

export type ColumnConfig = {
    id: string;
    label: string;
    sortable?: boolean;
};

export const COLUMNS: ColumnConfig[] = [
    { id: 'assetId', label: 'Asset ID', sortable: true },
    { id: 'productName', label: 'Product', sortable: true },
    { id: 'level', label: 'Level', sortable: true },
    { id: 'activationState', label: 'Activation State', sortable: true },
    { id: 'contractState', label: 'Contract State', sortable: true },
    { id: 'contractPeriod', label: 'Contract Period', sortable: true },
    { id: 'contractStarts', label: 'Contract Starts', sortable: true },
    { id: 'contractEnds', label: 'Contract Ends', sortable: true },
    { id: 'renewsTo', label: 'Contract Renews to', sortable: false },
];
