import type { ApiResourceActivations } from './activation.types';
import { sortingByName } from '../../common/utils/sortUtils';
import type { OverviewResource } from '../../utilities/types';
import { convertApiActivationState } from './convertApiActivationState';

export function mapResourceActivationsResponse(apiResourceActivations: ApiResourceActivations): OverviewResource[] {
    const activations = apiResourceActivations.activations.map(resource => ({
        id: resource.resource_id,
        name: resource.resource_name ? resource.resource_name : resource.resource_id,
        originLevel: resource.origin_level,
        targetLevel: resource.target_level,
        productState: convertApiActivationState(resource.activation_state),
    }));

    return activations.sort(sortingByName);
}
