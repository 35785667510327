import { isRight } from 'fp-ts/lib/Either';
import { PackageActivationResponseCodec } from './packageActivationCodec';
import type { PackageActivation } from './types';
import { de } from 'date-fns/locale';
import { format } from 'date-fns';

export const formatDate = (dateString: string) => {
    try {
        return format(new Date(dateString), 'dd.MM.yyyy HH:mm', { locale: de });
    } catch {
        return dateString;
    }
};

export class PackageActivationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PackageActivationError';
    }
}

export function mapPackageActivationsResponse(apiResponse: unknown): PackageActivation[] {
    const decodedResponse = PackageActivationResponseCodec.decode(apiResponse);
    if (isRight(decodedResponse)) {
        return decodedResponse.right.package_activations.map(activation => ({
            id: activation.id,
            accountId: activation.account_id,
            assetId: activation.asset_id,
            packageCode: activation.package_code,
            status: activation.status,
            bookingIntentOperationNumber: activation.booking_intent_operation_number,
            startsAt: activation.starts_at ? formatDate(activation.starts_at) : undefined,
            endsAt: activation.ends_at ? formatDate(activation.ends_at) : undefined,
            lastUpdatedAt: formatDate(activation.last_updated_at),
            succeededAt: activation.succeeded_at ? formatDate(activation.succeeded_at) : undefined,
        }));
    }

    throw new PackageActivationError(`Invalid package activation response ${JSON.stringify(apiResponse)}`);
}
