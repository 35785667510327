import { useLazyGetPackageActivationsQuery } from '../api/packageActivation/packageActivationApiSlice';
import Select from '@rio-cloud/rio-uikit/Select';
import { useEffect, useState } from 'react';
import type { PackageActivation, PackageActivationStatus } from '../api/packageActivation/types';
import BookingIntentCreationDialog from './BookingIntentCreationDialog';

const HEADER_MESSAGE_IDS = [
    'Package ID',
    'Account ID',
    'Asset ID',
    'Package Code',
    'Status',
    'Operation Number',
    'Starts',
    'Ends',
    'Last Updated',
    'Succeeded',
];

type StatusOption = {
    id: string;
    label: PackageActivationStatus | 'ALL';
    selected: boolean;
};

const STATUS_OPTIONS: StatusOption[] = [
    { id: '1', label: 'QUEUED' as PackageActivationStatus, selected: false },
    { id: '2', label: 'CREATED' as PackageActivationStatus, selected: false },
    { id: '3', label: 'FAILED' as PackageActivationStatus, selected: false },
    { id: '4', label: 'SUCCEEDED' as PackageActivationStatus, selected: false },
    { id: '5', label: 'REJECTED' as PackageActivationStatus, selected: true },
    { id: '6', label: 'CANCELLED' as PackageActivationStatus, selected: false },
    { id: '7', label: 'ALL', selected: false },
];

export const PackageActivationTable = () => {
    const [fetchedPackageActivations, setFetchedPackageActivations] = useState<PackageActivation[]>([]);
    const [getPackageActivations, { isLoading, isSuccess, isError, data }] = useLazyGetPackageActivationsQuery();
    const [_, setStatusOptions] = useState(STATUS_OPTIONS);
    const [filters, setFilters] = useState<{
        accountId?: string;
        assetId?: string;
        status?: PackageActivationStatus;
    }>({ status: 'REJECTED' });
    const [showBookingIntentDialog, setShowBookingIntentDialog] = useState(false);

    useEffect(() => {
        getPackageActivations(filters);
    }, [getPackageActivations, filters]);

    useEffect(() => {
        if (isSuccess && data) {
            setFetchedPackageActivations(data);
        }
    }, [isSuccess, data]);

    const handleAccountIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters({ ...filters, accountId: value });
    };

    const handleResourceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilters({ ...filters, assetId: value });
    };

    const handleStatusChange = (item: StatusOption) => {
        setFilters({ ...filters, status: item.label === 'ALL' ? undefined : item.label });

        const updatedStatusOptions = STATUS_OPTIONS.map(option => ({
            ...option,
            selected: option.id === item.id,
        }));
        setStatusOptions(updatedStatusOptions);
    };

    return (
        <div className={'container-fluid fluid-default'}>
            <BookingIntentCreationDialog
                show={showBookingIntentDialog}
                onClickCancel={() => setShowBookingIntentDialog(false)}
                onClickConfirm={() => setShowBookingIntentDialog(false)}
            />
            <div className='table-toolbar'>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <label className='table-toolbar-label'>Account Id</label>
                            <div className='table-toolbar-search input-group'>
                                <span className='input-group-addon'>
                                    <span className='rioglyph rioglyph-search' />
                                </span>
                                <div className='ClearableInput input-group'>
                                    <input
                                        type='text'
                                        placeholder='Search account...'
                                        className='form-control'
                                        onChange={handleAccountIdChange}
                                    />
                                    <span className='clearButton hide'>
                                        <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='table-toolbar-column'>
                            <label className='table-toolbar-label'>Asset Id</label>
                            <div className='table-toolbar-search input-group'>
                                <span className='input-group-addon'>
                                    <span className='rioglyph rioglyph-search' />
                                </span>
                                <div className='ClearableInput input-group'>
                                    <input
                                        type='text'
                                        placeholder='Search asset...'
                                        className='form-control'
                                        onChange={handleResourceIdChange}
                                    />
                                    <span className='clearButton hide'>
                                        <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='table-toolbar-column'>
                            <label className='table-toolbar-label'>Status</label>
                            <div className='input-group' data-testid={'packageStateSelector'}>
                                <Select
                                    id='status-select'
                                    options={STATUS_OPTIONS}
                                    onChange={item => {
                                        if (item) {
                                            handleStatusChange(item);
                                        }
                                    }}
                                    className='min-width-150'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column'>
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => setShowBookingIntentDialog(true)}
                            >
                                <span className='rioglyph rioglyph-plus-light' aria-hidden='true' />
                                <span className='text-capitalize'>Create booking intent</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table table-hover table-head-filled table-bordered'>
                    <thead>
                        <tr>
                            {HEADER_MESSAGE_IDS.map(id => (
                                <th key={id}>{id}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={HEADER_MESSAGE_IDS.length}>Loading...</td>
                            </tr>
                        ) : isError ? (
                            <tr>
                                <td colSpan={HEADER_MESSAGE_IDS.length}>Error fetching package activations</td>
                            </tr>
                        ) : (
                            fetchedPackageActivations.map(packageActivation => (
                                <tr key={packageActivation.id}>
                                    <td>{packageActivation.id}</td>
                                    <td>{packageActivation.accountId}</td>
                                    <td>{packageActivation.assetId}</td>
                                    <td>{packageActivation.packageCode}</td>
                                    <td>{packageActivation.status}</td>
                                    <td>{packageActivation.bookingIntentOperationNumber}</td>
                                    <td>{packageActivation.startsAt}</td>
                                    <td>{packageActivation.endsAt}</td>
                                    <td>{packageActivation.lastUpdatedAt}</td>
                                    <td>{packageActivation.succeededAt ? 'Yes' : 'No'}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const PackageActivationTableContainer = PackageActivationTable;
