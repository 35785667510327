import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useState } from 'react';
import { ConditionallyEnabledButton } from './ConditionallyEnabledButton';
import { deactivateResourcesForProduct } from '../api/activation/deactivationCall';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { splitResourceIds } from './resourceIdSplitter';

export const ForcedCancellationProductMultipleAccounts = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [accountIds, setAccountIds] = useState<string>('');
    const [productId, setProductId] = useState<string>('');
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
    const accountIdsArray = splitResourceIds(accountIds);
    const missingAccountIds = accountIdsArray.length === 0;
    const missingProductId = !productId;

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Forced deactivation for product</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Product id*'}</label>
                        <ClearableInput
                            placeholder='Insert product id'
                            value={productId}
                            onChange={(productId: string) => setProductId(productId.trim())}
                        />
                        <label>{'Account ids*'}</label>
                        <ClearableInput
                            placeholder='Insert account ids'
                            value={accountIds}
                            onChange={(accountIds: string) => setAccountIds(accountIds.trim())}
                        />
                    </div>
                </div>
                <ConditionallyEnabledButton
                    disabledText={
                        missingProductId
                            ? 'Please enter product id'
                            : missingAccountIds
                              ? 'Please enter account ids'
                              : undefined
                    }
                    onClick={async () => {
                        setShowDialog(true);
                    }}
                >
                    Deactivate
                </ConditionallyEnabledButton>
                <ConfirmationDialog
                    show={showDialog}
                    content={
                        <div>
                            Do you really want to deactivate {productId} for the following {accountIdsArray.length}{' '}
                            account ids?
                            <ul className={'margin-top-20 max-height-75vh overflow-auto'}>
                                {accountIdsArray.map(accountId => (
                                    <li key={accountId}>{accountId}</li>
                                ))}
                            </ul>
                        </div>
                    }
                    confirmButtonText={isDeactivating ? 'Deactivating...' : 'Yes'}
                    cancelButtonText={'No'}
                    disableConfirm={isDeactivating}
                    onClickCancel={() => setShowDialog(false)}
                    onClickConfirm={async () => {
                        setIsDeactivating(true);
                        try {
                            for (const accountId of accountIdsArray) {
                                await deactivateResourcesForProduct(accountId, productId, undefined);
                            }
                            setShowDialog(false);
                            Notification.success(
                                `Successfully deactivated ${productId} for ${accountIdsArray.length} accounts`
                            );
                            // biome-ignore lint/suspicious/noExplicitAny: legacy
                        } catch (e: any) {
                            Notification.error(`Could not deactivate service: ${e.message}`);
                        }
                        setIsDeactivating(false);
                    }}
                    title={'Forced deactivation'}
                    useOverflow={false}
                />
            </ExpanderPanel>
        </div>
    );
};
