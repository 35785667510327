import { reportErrorToSentry } from '../setup/sentry';

const MODULE_NAME = 'marketplace-admin-frontend';

export const sendError = (exception: Error) => {
    reportErrorToSentry(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};
