import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getMarketplaceBackend } from '../../config/appConfig';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import type { MergedActivationInfo } from './types';
import { mapContractResponse } from '../contract/contractMapper';
import { mapMergedActivations } from './mergedActivationMapper';
import { type ApiActivationState, ApiResourceActivationsCodec } from './activation.types';
import { ApiContractsCodec } from '../contract/types';
import { isRight } from 'fp-ts/lib/Either';
import { mapResourceActivations } from './resourceActivationMapper';

export const mergedActivationApiSlice = createApi({
    reducerPath: 'mergedActivationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getMarketplaceBackend(),
        prepareHeaders: headers => {
            const token = accessToken.getAccessToken();
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: builder => ({
        getMergedActivations: builder.query<
            MergedActivationInfo[],
            { accountId: string; activationStates?: ApiActivationState[] }
        >({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                try {
                    const activationsResult = await fetchWithBQ({
                        url: `admin/${arg.accountId}/activations${arg.activationStates !== undefined ? `?states=${arg.activationStates.join(',')}` : ''}`,
                    });

                    if (activationsResult.error) {
                        return { error: activationsResult.error };
                    }

                    const decodedActivations = ApiResourceActivationsCodec.decode(activationsResult.data);
                    if (!isRight(decodedActivations)) {
                        return {
                            error: {
                                status: 'CUSTOM_ERROR',
                                error: 'Invalid activation response format',
                                data: activationsResult.data,
                            },
                        };
                    }

                    const activations = mapResourceActivations(decodedActivations.right);

                    const contractsResult = await fetchWithBQ(`admin/${arg.accountId}/contracts`);
                    if (contractsResult.error) {
                        return { error: contractsResult.error };
                    }

                    const decodedContracts = ApiContractsCodec.decode(contractsResult.data);
                    if (!isRight(decodedContracts)) {
                        return {
                            error: {
                                status: 'CUSTOM_ERROR',
                                error: 'Invalid contracts response format',
                                data: contractsResult.data,
                            },
                        };
                    }

                    const contracts = mapContractResponse(decodedContracts.right);

                    return {
                        data: mapMergedActivations(arg.accountId, activations, contracts),
                    };
                } catch (error) {
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            error: error instanceof Error ? error.message : 'Unknown error occurred',
                        },
                    };
                }
            },
        }),
    }),
});

export const { useGetMergedActivationsQuery, useLazyGetMergedActivationsQuery } = mergedActivationApiSlice;
