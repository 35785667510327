export enum ApiCalls {
    DISCOUNTS = 'discounts',
    ADMIN_CAMPAIGNS = 'adminCampaigns',
}

export interface ApiLoadingState {
    loading: { [key: string]: boolean };
    error: { [key: string]: boolean };
}

export interface SetLoadingPayload {
    type: ApiCalls;
    loading?: boolean;
    error?: boolean;
}
