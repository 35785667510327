import type { MergedActivationInfo } from '../../api/activation/types';
import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';
import { orderBy } from 'lodash';

type LodashSortOrder = 'asc' | 'desc';

const convertSortDirection = (dir: SortDirectionType | null): LodashSortOrder => {
    if (!dir) {
        return 'asc';
    }
    return dir === SortDirection.ASCENDING ? 'asc' : 'desc';
};

const PERIOD_REGEX = /^P(\d+)([MYD])$/;

const getPeriodValue = (period: string | undefined): number => {
    if (!period) {
        return 0;
    }
    const match = period.match(PERIOD_REGEX);
    if (!match) {
        return 0;
    }

    const [, amount, unit] = match;
    const value = Number.parseInt(amount, 10);

    switch (unit) {
        case 'Y':
            return value * 12;
        case 'D':
            return value / 30;
        default:
            return value;
    }
};

export const sortData = (
    data: MergedActivationInfo[] | undefined,
    sortBy: string | null,
    sortDir: SortDirectionType | null,
    secondarySortBy = 'productName'
): MergedActivationInfo[] | undefined => {
    if (!data) {
        return data;
    }

    const sortFields = sortBy ? [sortBy, secondarySortBy] : ['assetId', 'productName'];
    const sortOrders: LodashSortOrder[] = sortBy ? [convertSortDirection(sortDir), 'asc'] : ['asc', 'asc'];

    if (sortBy === 'contractPeriod') {
        return [...data].sort((a, b) => {
            const aValue = getPeriodValue(a.contractPeriod);
            const bValue = getPeriodValue(b.contractPeriod);
            return sortDir === SortDirection.ASCENDING ? aValue - bValue : bValue - aValue;
        });
    }

    return orderBy(data, sortFields, sortOrders);
};
