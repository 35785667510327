import { isRight } from 'fp-ts/lib/Either';
import { ApiActivationsSummaryCodec } from './activation.types';
import type { ActivationSummary } from './activation.types';

export class ActivationSummaryError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ActivationSummaryError';
    }
}

export function mapActivationSummaryResponse(apiResponse: unknown): ActivationSummary[] {
    const decodedResponse = ApiActivationsSummaryCodec.decode(apiResponse);
    if (isRight(decodedResponse)) {
        return decodedResponse.right.activations.map(it => ({
            productId: it.product_id,
            amount: it.amount,
        }));
    }

    throw new ActivationSummaryError(`Invalid activation summary response ${JSON.stringify(apiResponse)}`);
}
