import { useCallback, useEffect, useState, useMemo } from 'react';
import { AccountIdInput, ProductIdSelect, ResourceIdsInput } from '../ActivationsTableInputs';
import { debounce } from 'lodash';
import Switch from '@rio-cloud/rio-uikit/Switch';
import type { Filters } from '../types';
import type { products } from '../../utilities/ProductSelect';

interface TableFiltersProps {
    filters: Filters;
    setFilters: (filters: (prev: Filters) => Filters) => void;
    visibleProducts: typeof products;
}

export const TableFilters: React.FC<TableFiltersProps> = ({ filters, setFilters, visibleProducts }) => {
    const [accountIdInput, setAccountIdInput] = useState(filters.accountId || '');
    const [resourceIdsInput, setResourceIdsInput] = useState(filters.resourceIds?.join(',') || '');

    const debouncedSetAccountId = useMemo(
        () =>
            debounce((value: string) => {
                setFilters(prev => ({ ...prev, accountId: value || undefined }));
            }, 600),
        [setFilters]
    );

    const debouncedSetResourceIds = useMemo(
        () =>
            debounce((value: string) => {
                setFilters(prev => ({
                    ...prev,
                    resourceIds: value ? value.split(/[,;]/).map(id => id.toLowerCase().trim()) : undefined,
                }));
            }, 600),
        [setFilters]
    );

    useEffect(() => {
        return () => {
            debouncedSetAccountId.cancel();
            debouncedSetResourceIds.cancel();
        };
    }, [debouncedSetAccountId, debouncedSetResourceIds]);

    const handleAccountIdChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setAccountIdInput(newValue);
            debouncedSetAccountId(newValue);
        },
        [debouncedSetAccountId]
    );

    const handleResourceIdsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setResourceIdsInput(newValue);
            debouncedSetResourceIds(newValue);
        },
        [debouncedSetResourceIds]
    );

    return (
        <div className='table-toolbar-container'>
            <div className='table-toolbar-group-left'>
                <AccountIdInput value={accountIdInput} onChange={handleAccountIdChange} />
                <ResourceIdsInput value={resourceIdsInput} onChange={handleResourceIdsChange} />
                <ProductIdSelect
                    options={visibleProducts.map(product => ({
                        id: product.id,
                        label: `${product.name} (${product.id})`,
                        selected: filters.productId === product.id,
                    }))}
                    onChange={e => setFilters(prev => ({ ...prev, productId: e?.id }))}
                />
                <div className='table-toolbar-column padding-bottom-5'>
                    <Switch
                        checked={filters.isOnlyContracts}
                        onChange={() => setFilters(prev => ({ ...prev, isOnlyContracts: !prev.isOnlyContracts }))}
                    >
                        <span>Only contracts</span>
                    </Switch>
                </div>
                <div className='table-toolbar-column padding-bottom-5'>
                    <Switch
                        checked={filters.isOnlyPaid}
                        onChange={() => setFilters(prev => ({ ...prev, isOnlyPaid: !prev.isOnlyPaid }))}
                    >
                        <span>Only paid</span>
                    </Switch>
                </div>
                <div className='table-toolbar-column padding-bottom-5'>
                    <Switch
                        checked={!filters.showInactive}
                        onChange={() => setFilters(prev => ({ ...prev, showInactive: !prev.showInactive }))}
                    >
                        <span>Hide inactive</span>
                    </Switch>
                </div>
            </div>
        </div>
    );
};
