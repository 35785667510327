import type React from 'react';
import { memo } from 'react';
import Select from '@rio-cloud/rio-uikit/components/selects/Select';
import type { SelectOption } from '@rio-cloud/rio-uikit/Select';

interface AccountIdInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AccountIdInput = memo<AccountIdInputProps>(({ value, onChange }) => (
    <div className='table-toolbar-column'>
        <label className='table-toolbar-label'>
            Account Id<strong>*</strong>
        </label>
        <div className='table-toolbar-search input-group'>
            <span className='input-group-addon'>
                <span className='rioglyph rioglyph-search' />
            </span>
            <div className='ClearableInput input-group'>
                <input
                    type='text'
                    placeholder='Search account...'
                    className='form-control'
                    value={value}
                    onChange={onChange}
                />
                {value && (
                    <span
                        className='clearButton'
                        onClick={() => onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                        <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                    </span>
                )}
            </div>
        </div>
    </div>
));

AccountIdInput.displayName = 'AccountIdInput';

interface ResourceIdInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ResourceIdsInput: React.FC<ResourceIdInputProps> = ({ value, onChange }) => (
    <div className='table-toolbar-column'>
        <label className='table-toolbar-label'>Asset Ids</label>
        <div className='table-toolbar-search input-group'>
            <span className='input-group-addon'>
                <span className='rioglyph rioglyph-search' />
            </span>
            <div className='ClearableInput input-group'>
                <input
                    type='text'
                    placeholder='AssetId1,AssetId2,...'
                    className='form-control'
                    value={value}
                    onChange={onChange}
                />
                {value && (
                    <span
                        className='clearButton'
                        onClick={() => onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                        <span className='clearButtonIcon rioglyph rioglyph-remove-sign' />
                    </span>
                )}
            </div>
        </div>
    </div>
);

interface ProductIdSelectProps {
    options: SelectOption[];
    onChange: (e?: SelectOption) => void;
}

export const ProductIdSelect: React.FC<ProductIdSelectProps> = ({ options, onChange }) => (
    <div className='table-toolbar-column flex-1-0'>
        <label htmlFor='productId' className='table-toolbar-label'>
            Product
        </label>
        <Select
            id='productId'
            placeholder='Please select'
            options={options}
            useFilter
            noItemMessage='Nothing found'
            onChange={onChange}
            useClear={true}
        />
    </div>
);
