import FilePicker from '@rio-cloud/rio-uikit/FilePicker';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useState } from 'react';
import { XLSX_TYPE } from './fileTypes';
import { uploadDiscountExcel } from '../api/discount/discountCalls';
import type { DiscountType } from '../api/discount/discount.types';

export function DiscountUpload(props: { discountType: DiscountType }) {
    const [isUploading, setIsUploading] = useState(false);
    const label = (
        <>{!isUploading && <span className={'rioglyph rioglyph-cloud-upload'} aria-hidden='true' />} Upload discounts</>
    );

    function onPick(files: FileList | null) {
        if (files && !isUploading && files.length === 1) {
            uploadDiscountExcel(files[0], setIsUploading, props.discountType);
        } else {
            Notification.error('No file selected');
        }
    }

    return (
        <FilePicker
            key={isUploading ? 'up' : 'ready'}
            displayMode={'full'}
            onPick={onPick}
            label={label}
            accept={{ [XLSX_TYPE]: ['xlsx'] }}
            className={isUploading ? 'btn-loading disabled' : undefined}
        />
    );
}
