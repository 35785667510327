import { type FC, useState } from 'react';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import type { ActivationsTableContentProps } from '../types';
import { COLUMNS } from '../types';
import { formatActivationState, formatContractState, formatDate, formatPeriod, formatRenewalInfo } from '../utils';
import classNames from 'classnames';
import { CopyableCell } from './CopyableCell';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import type { MergedActivationInfo } from '../../api/activation/types';
import BatchButton from './BatchButton';
import CancellationConfirmationDialog from './CancellationConfirmationDialog';
import type { ApiSubscriptionCancelPayload } from '../../api/activation/activation.types';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import { useDeactivateMutation } from '../../api/activation/activationApiSlice';
import { groupBy, map } from 'lodash';
import ContractEndDateEditingDialog from './ContractEndDateEditingDialog';

export const ActivationsTableContent: FC<ActivationsTableContentProps> = ({
    isLoading,
    isError,
    filteredData,
    sortBy,
    sortDir,
    onSort,
    onReload,
}) => {
    const [adminDeactivation] = useDeactivateMutation();
    const [checkedRowIds, setCheckedRowIds] = useState<string[]>([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showContractEndDateEditDialog, setShowContractEndDateEditDialog] = useState(false);

    const renderTableHeader = (column: (typeof COLUMNS)[0]) => {
        if (!column.sortable) {
            return <th key={column.id}>{column.label}</th>;
        }
        return (
            <th
                key={column.id}
                className={classNames('user-select-none', 'sort-column')}
                onClick={() => onSort(column.id)}
                data-field={column.id}
                data-sortby={column.id}
                title={column.label}
            >
                <span>
                    {sortBy === column.id && sortDir ? <SortArrows direction={sortDir} /> : <SortArrows />}
                    <span>{column.label}</span>
                </span>
            </th>
        );
    };

    const handleClickOnRow = (rowId: string) => {
        checkedRowIds.includes(rowId)
            ? setCheckedRowIds(checkedRowIds.filter(id => id !== rowId))
            : setCheckedRowIds([...checkedRowIds, rowId]);
    };

    const selectedData = checkedRowIds
        .map(id => filteredData?.find(item => getId(item) === id))
        .filter((item): item is MergedActivationInfo => item !== undefined);

    const handleConfirm = async (reason: string) => {
        const accountId = selectedData.map(it => it.accountId)[0];
        const grouped = groupBy(selectedData, 'productId');
        const groupedData = new Map(map(grouped, (items, productId) => [productId, items.map(item => item.assetId)]));

        const deactivationPromises = Array.from(groupedData.entries()).map(item => {
            const productId = item[0];
            const assetIds = item[1];
            const deactivation: ApiSubscriptionCancelPayload = {
                resource_ids: assetIds,
                reason: reason,
            };

            return adminDeactivation({ accountId, productId, body: deactivation }).unwrap();
        });

        return Promise.all(deactivationPromises)
            .then(() => Notification.success('Successfully deactivated selected activations!'))
            .catch((error: unknown) => {
                Notification.error(
                    `Error occurred, please check the table to confirm if operation worked as expected: ${JSON.stringify(error)}`,
                    'Deactivation error',
                    999999
                );
                reportErrorToSentry(error);
            });
    };

    return (
        <div>
            <table className='table table-layout-fixed table-column-overflow-hidden table-bordered table-head-filled table-hover table-sticky'>
                <thead>
                    <tr>
                        <th className={'table-checkbox'}>
                            <BatchButton
                                checkedRowIds={checkedRowIds}
                                onSelectAll={() =>
                                    setCheckedRowIds(
                                        filteredData
                                            ?.filter(item => !isContractInactiveOrActivationInactive(item))
                                            .map(item => getId(item)) || []
                                    )
                                }
                                onDeselectAll={() => setCheckedRowIds([])}
                                handleBulkCancel={() => setShowConfirmationDialog(true)}
                                handleBulkContractEditing={() => setShowContractEndDateEditDialog(true)}
                            />
                        </th>
                        {COLUMNS.map(renderTableHeader)}
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={COLUMNS_COUNT}>Loading...</td>
                        </tr>
                    ) : isError ? (
                        <tr>
                            <td colSpan={COLUMNS_COUNT}>Error fetching data</td>
                        </tr>
                    ) : !filteredData?.length ? (
                        <tr>
                            <td colSpan={COLUMNS_COUNT}>No data found. Make sure to enter a valid account id.</td>
                        </tr>
                    ) : (
                        filteredData.map(item => (
                            <tr key={`${item.accountId}-${item.assetId}-${item.productId}`}>
                                <td>
                                    <Checkbox
                                        name={getId(item)}
                                        checked={checkedRowIds.includes(getId(item))}
                                        disabled={isContractInactiveOrActivationInactive(item)}
                                        onClick={() => handleClickOnRow(getId(item))}
                                    />
                                </td>
                                <td>
                                    <CopyableCell value={item.assetId} />
                                </td>
                                <td>{item.productName}</td>
                                <td>{item.level}</td>
                                <td>{formatActivationState(item.activationState)}</td>
                                <td>{formatContractState(item.contractState)}</td>
                                <td>{formatPeriod(item.contractPeriod)}</td>
                                <td>{formatDate(item.contractStarts)}</td>
                                <td>{formatDate(item.contractEnds)}</td>
                                <td>{formatRenewalInfo(item)}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <CancellationConfirmationDialog
                data={selectedData}
                show={showConfirmationDialog}
                onClickCancel={() => setShowConfirmationDialog(false)}
                onClickConfirm={async reason => {
                    await handleConfirm(reason);
                    setShowConfirmationDialog(false);
                    setCheckedRowIds([]);
                    onReload();
                }}
            />
            <ContractEndDateEditingDialog
                data={selectedData}
                show={showContractEndDateEditDialog}
                onClickCancel={() => setShowContractEndDateEditDialog(false)}
                onClickConfirm={() => setShowContractEndDateEditDialog(false)}
                onSuccess={onReload}
            />
        </div>
    );
};

const isContractInactiveOrActivationInactive = (item: MergedActivationInfo) =>
    item?.contractState === 'inactive' || (item.activationState === 'inactive' && item.contractState === undefined);
const COLUMNS_COUNT = COLUMNS.length + 1;
const getId = (item: MergedActivationInfo) => `${item.assetId}${item.productId}`;
