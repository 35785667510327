import type { ReactElement } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';

interface CopyableCellProps {
    value: string;
}

export const CopyableCell = ({ value }: CopyableCellProps): ReactElement => {
    const handleCopy = () => {
        navigator.clipboard.writeText(value);
    };

    return (
        <div className='display-flex justify-content-between align-items-center' style={{ width: '100%' }}>
            <span className='flex-grow-1 text-truncate'>{value}</span>
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id={`copy-tooltip-${value}`} allowOnTouch>
                        Copy
                    </Tooltip>
                }
            >
                <Button bsStyle='muted' bsSize='xs' className='btn-icon-only' onClick={handleCopy}>
                    <span className='rioglyph rioglyph-clipboard' />
                </Button>
            </OverlayTrigger>
        </div>
    );
};
