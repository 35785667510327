import { useState, useEffect } from 'react';
import type { Filters } from '../types';

export const useTableFilters = () => {
    const [filters, setFilters] = useState<Filters>(() => {
        const url = new URL(window.location.href);
        return {
            accountId: url.searchParams.get('accountId') || undefined,
            resourceIds: url.searchParams.get('resourceIds')?.split(','),
            productId: url.searchParams.get('productId') || undefined,
            isOnlyContracts: url.searchParams.get('isOnlyContracts') === 'true',
            isOnlyPaid: url.searchParams.get('isOnlyPaid') === 'true',
            showInactive: url.searchParams.get('showInactive') === 'true',
        };
    });

    useEffect(() => {
        const url = new URL(window.location.href);
        Object.entries(filters).forEach(([key, value]) => {
            if (value === undefined) {
                url.searchParams.delete(key);
            } else if (typeof value === 'boolean') {
                if (value) {
                    url.searchParams.set(key, String(value));
                } else {
                    url.searchParams.delete(key);
                }
            } else if (Array.isArray(value)) {
                if (value.length > 0) {
                    url.searchParams.set(key, value.join(','));
                } else {
                    url.searchParams.delete(key);
                }
            } else if (value) {
                url.searchParams.set(key, String(value));
            } else {
                url.searchParams.delete(key);
            }
        });
        window.history.pushState({}, '', url.toString());
    }, [filters]);

    return { filters, setFilters };
};
