import { UsageReportDownload } from './UsageReportDownload';
import { CampaignsContainer } from './Campaigns';
import { DiscountPanel } from './DiscountPanel';
import { DiscountType } from '../api/discount/discount.types';

const UsageReportPanel = () => (
    <div className='panel panel-info'>
        <div className='panel-heading'>Discounts report</div>
        <div className='panel-body display-flex'>
            <UsageReportDownload />
        </div>
    </div>
);

const CampaignsPanel = () => (
    <div className='panel panel-secondary'>
        <div className='panel-heading'>Campaigns</div>
        <div className='panel-body'>
            <CampaignsContainer />
        </div>
    </div>
);

export const DiscountPage = () => {
    return (
        <>
            <div className='module-content'>
                <div className='container-fluid fluid-default margin-bottom-10pct'>
                    <DiscountPanel panelType={DiscountType.ACCOUNT} />
                    <DiscountPanel panelType={DiscountType.PRODUCT} />
                    <DiscountPanel panelType={DiscountType.ASSET} />
                    <UsageReportPanel />
                    <CampaignsPanel />
                </div>
            </div>
        </>
    );
};
