import { isRight } from 'fp-ts/lib/Either';
import { ApiContractsCodec } from './types';
import type { Contract } from './types';

export class ContractMapperError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ContractMapperError';
    }
}

export function mapContractResponse(response: unknown): Contract[] {
    const decoded = ApiContractsCodec.decode(response);
    if (!isRight(decoded)) {
        throw new ContractMapperError(`Invalid contract response: ${JSON.stringify(response)}`);
    }

    return decoded.right.contracts.map(contract => ({
        resourceId: contract.resource_id,
        resourceType: contract.resource_type,
        productId: contract.product_id,
        level: contract.level,
        state: contract.state,
        activationState: contract.activation_state,
        period: contract.period,
        productName: contract.product_name,
        productLevelName: contract.product_level_name,
        productVariantName: contract.product_variant_name,
        paid: contract.paid,
        resourceName: contract.resource_name,
        startsAt: contract.starts_at,
        endsAt: contract.ends_at,
        renewsTo: contract.renews_to
            ? {
                  level: contract.renews_to.level,
                  productLevelName: contract.renews_to.product_level_name,
                  productVariantName: contract.renews_to.product_variant_name,
                  period: contract.renews_to.period,
              }
            : undefined,
    }));
}
