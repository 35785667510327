import type React from 'react';

interface Props {
    disabledText: string | undefined;
    // biome-ignore lint/complexity/noBannedTypes: legacy
    onClick: Function;
    children: React.ReactNode;
}

export const ConditionallyEnabledButton = ({ disabledText, onClick, children }: Props) => (
    <>
        {disabledText && (
            <div className='text-color-warning'>
                <span className='rioglyph rioglyph-warning-sign margin-right-5' />
                <b>{disabledText}</b>
            </div>
        )}
        <button
            type={'button'}
            className='btn btn-primary pull-right'
            disabled={Boolean(disabledText)}
            onClick={() => onClick()}
        >
            {children}
        </button>
    </>
);
