import * as Sentry from '@sentry/browser';
import { config } from '../../config';

if (process.env.NODE_ENV === 'production') {
    const release = APP_VERSION;
    const environment = 'production';

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        ignoreErrors: ['Need to sign in'],
        environment,
        release,
    });
}

// biome-ignore lint/suspicious/noExplicitAny: legacy
export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
