import { getMarketplaceBackend } from '../../config/appConfig';
import { patchRequest } from '../requests';
import { rejectIfNotOk } from '../api';

export async function setFreeTrialEligible(accountId: string): Promise<void> {
    const body = { eligible: true };

    const response = await fetch(`${getMarketplaceBackend()}/admin/${accountId}/free-trial`, patchRequest(body));
    return rejectIfNotOk(response);
}
