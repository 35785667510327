import type React from 'react';
import { useState } from 'react';
import type { MergedActivationInfo } from '../../api/activation/types';
import ClearableInput from '@rio-cloud/rio-uikit/components/clearableInput/ClearableInput';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';

type CancellationConfirmationDialog = {
    data: MergedActivationInfo[];
    show: boolean;
    onClickCancel: () => void;
    onClickConfirm: (reason: string) => void;
};

const CancellationConfirmationDialog: React.FC<CancellationConfirmationDialog> = ({
    data,
    show,
    onClickCancel,
    onClickConfirm,
}) => {
    const [reason, setReason] = useState('');
    const [confirmation, setConfirmation] = useState('');

    const cancellationConfirmed = confirmation === 'CANCEL' || confirmation === 'cancel';

    const content = (
        <div className='table-responsive'>
            <div className='form-group'>
                <label htmlFor='reason'>
                    <h5>Ticket number or reason</h5>
                </label>
                <ClearableInput id='reason' value={reason} onChange={newValue => setReason(newValue)} />
            </div>
            <table className='table table-hover table-head-filled table-bordered'>
                <thead>
                    <tr>
                        {COLUMNS.map(column => (
                            <th key={column.id}>{column.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr key={`dialog-${item.accountId}-${item.assetId}-${item.productId}`}>
                            <td>{item.accountId}</td>
                            <td>{item.assetId}</td>
                            <td>{item.productName}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='form-group'>
                <label htmlFor='confirmation'>
                    <h5>
                        If you are sure, please type{' '}
                        <span className='text-bold text-color-danger pointer-events-none user-select-none'>CANCEL</span>
                    </h5>
                </label>
                <ClearableInput
                    id='confirmation'
                    value={confirmation}
                    onChange={newValue => setConfirmation(newValue)}
                />
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={show}
            content={content}
            cancelButtonText='Abort'
            confirmButtonText={
                <>
                    <span className='rioglyph rioglyph-ok margin-right-5' />
                    <span>CANCEL ALL</span>
                </>
            }
            disableConfirm={reason.trim() === '' || !cancellationConfirmed}
            onClickCancel={() => {
                onClickCancel();
                setReason('');
                setConfirmation('');
            }}
            onClickConfirm={() => {
                onClickConfirm(reason);
                setReason('');
                setConfirmation('');
            }}
            title={'Please confirm the cancellations'}
            useOverflow={true}
        />
    );
};

const COLUMNS = [
    { id: 'accountId', label: 'Account ID' },
    { id: 'assetId', label: 'Asset ID' },
    { id: 'productName', label: 'Product' },
];

export default CancellationConfirmationDialog;
