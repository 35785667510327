import { getRequest } from '../requests';
import { blobOrReject } from '../api';
import FileSaver from 'file-saver';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { sendError } from '../../../../configuration/lang/services';
import { getMarketplaceBackend } from '../../config/appConfig';
import { getCurrentDate } from './dateUtil';

function formatDate(date: Date): string {
    const months = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const days = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${date.getFullYear()}-${months}-${days}`;
}

export const downloadDiscountExcelReport = (
    setIsDownloading: (isSet: boolean) => void,
    year: number,
    month: number
) => {
    setIsDownloading(true);
    const fromDate = formatDate(new Date(year, month - 1, 1));
    const toDate = formatDate(new Date(year, month, 0));
    const params = `?from=${fromDate}&to=${toDate}`;
    return fetch(`${getMarketplaceBackend()}/admin/usages/export${params}`, getRequest())
        .then(blobOrReject)
        .then(response =>
            FileSaver.saveAs(response, `RIO-All-Usages-${year}-${month}-${getCurrentDate().toISOString()}.xlsx`)
        )
        .catch(error => {
            sendError(error);
            Notification.error('Error while downloading the discount excel');
        })
        .finally(() => setIsDownloading(false));
};
