import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getMarketplaceBackend } from '../../config/appConfig';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import type { ActivationSummary, ApiSubscribePayload, ApiSubscriptionCancelPayload } from './activation.types';
import type { BookableInfo, OverviewResource } from '../../utilities/types';
import { mapActivationSummaryResponse } from './activationSummaryMapper';
import { mapResourceActivationsResponse } from './resourceMapper';
import { mapBookableResponse } from './bookableMapper';

export type DeactivationParameters = { accountId: string; productId: string; body?: ApiSubscriptionCancelPayload };

export const activationApiSlice = createApi({
    reducerPath: 'activationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getMarketplaceBackend(),
        prepareHeaders: headers => {
            const token = accessToken.getAccessToken();
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: builder => ({
        getActivationsSummary: builder.query<ActivationSummary[], string>({
            query: accountId => `${getMarketplaceBackend()}/admin/${accountId}/activations/summary`,
            transformResponse(baseQueryReturnValue: unknown) {
                return mapActivationSummaryResponse(baseQueryReturnValue);
            },
        }),

        getActivationsForProduct: builder.query<OverviewResource[], { accountId: string; productId: string }>({
            query: ({ accountId, productId }) => ({
                url: `admin/${accountId}/activations`,
                params: { product_id: productId },
            }),
            transformResponse: mapResourceActivationsResponse,
        }),

        getBookableInfos: builder.query<
            BookableInfo[],
            { accountId: string; productId: string; level?: string; resourceIds?: string }
        >({
            query: ({ accountId, productId, level, resourceIds }) => {
                const queryParams = new URLSearchParams();
                if (level) {
                    queryParams.append('level', level);
                }
                if (resourceIds) {
                    queryParams.append('resource_ids', resourceIds);
                }
                const queryString = queryParams.toString();
                return `admin/${accountId}/activations/${productId}/bookable${queryString ? `?${queryString}` : ''}`;
            },
            transformResponse: mapBookableResponse,
        }),

        subscribe: builder.mutation<void, { accountId: string; body: ApiSubscribePayload }>({
            query: ({ accountId, body }) => ({
                url: `admin/${accountId}/activations`,
                method: 'POST',
                body,
            }),
        }),

        deactivate: builder.mutation<void, DeactivationParameters>({
            query: ({ accountId, productId, body }) => ({
                url: `admin/${accountId}/activations/${productId}/deactivate`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const {
    useGetActivationsSummaryQuery,
    useLazyGetActivationsSummaryQuery,
    useGetActivationsForProductQuery,
    useLazyGetActivationsForProductQuery,
    useGetBookableInfosQuery,
    useLazyGetBookableInfosQuery,
    useSubscribeMutation,
    useDeactivateMutation,
} = activationApiSlice;
