import { useInvokeCommandMutation } from '../api/packageActivation/packageActivationApiSlice';
import { uniq } from 'lodash';
import type { PackageAdminCommand } from '../api/packageActivation/types';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import Notification from '@rio-cloud/rio-uikit/Notification';
import moment from 'moment';

export const useCreateBookingIntentsCommand = () => {
    const [invokeCommand] = useInvokeCommandMutation();

    return async (data: {
        vins: string[];
        startDate: string;
        country: string;
        operationNumberPrefix: string;
    }) => {
        const errors = [];
        const isoDate = moment(data.startDate).startOf('day').toISOString();

        for (const vin of uniq(data.vins)) {
            const invokeParams: PackageAdminCommand = {
                command: 'ReceiveBookingIntentCommand',
                payload: {
                    operationNumber: `${data.operationNumberPrefix}-${vin}`,
                    occurredAt: isoDate,
                    vin: vin,
                    packageCode: '0PIKA',
                    soldToPartyCountryCode: data.country,
                    processFlag: 'I',
                    invoicedAt: isoDate,
                },
            };

            try {
                await invokeCommand(invokeParams).unwrap();
            } catch (e) {
                // @ts-ignore
                errors.push({ vin, error: e });
                reportErrorToSentry('Error creating booking intent', e);
                console.error(`Error creating booking intent for VIN ${vin}`, e);
            }
        }

        if (errors.length === 0) {
            Notification.success(`Successfully created ${data.vins.length} booking intent(s)`);
        } else {
            Notification.error(
                `Error occurred for VINs ${errors.map(e => e.vin).join(', ')}. Details: ${JSON.stringify(errors)}`,
                'Error creating booking intents',
                999999
            );
        }
    };
};
