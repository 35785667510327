import { DiscountType } from '../api/discount/discount.types';
import { DiscountUpload } from './DiscountUpload';
import { DiscountDownload } from './DiscountDownload';

const getPanelTitle = (discountType: DiscountType) => {
    switch (discountType) {
        case DiscountType.ACCOUNT:
            return 'Account Discounts';
        case DiscountType.PRODUCT:
            return 'Product Discounts';
        default:
            return 'Asset Discounts';
    }
};

const getPanelIcon = (discountType: DiscountType) => {
    let iconClass: string;

    switch (discountType) {
        case DiscountType.ACCOUNT:
            iconClass = 'rioglyph-user';
            break;
        case DiscountType.PRODUCT:
            iconClass = 'rioglyph-parcel';
            break;
        default:
            iconClass = 'rioglyph-truck-baseline';
    }

    return <span className={`rioglyph ${iconClass} text-size-20 margin-right-10`} />;
};

export function DiscountPanel(props: { panelType: DiscountType }) {
    const { panelType } = props;
    const panelTitle = getPanelTitle(panelType);
    const panelIcon = getPanelIcon(panelType);

    return (
        <div className='panel panel-primary'>
            <div className='panel-heading'>
                {panelIcon}
                {panelTitle}
            </div>
            <div className='panel-body display-flex'>
                <div className='margin-right-10'>
                    <DiscountUpload discountType={panelType} />
                </div>
                <div>
                    <DiscountDownload discountType={panelType} />
                </div>
            </div>
        </div>
    );
}
