import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export const formatDate = (dateString: string | undefined): string => {
    if (!dateString) {
        return '';
    }

    try {
        return format(new Date(dateString), 'dd.MM.yyyy', { locale: de });
    } catch {
        return dateString;
    }
};
