import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';

export const InputExample = () => {
    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Example input</h5>} bsStyle='default'>
                <p>{'// in browser console'}</p>
                <p>window.input = [</p>
                <p>{'{'}</p>
                <p className={'padding-left-10'}>{'accountId: "accountId1",'}</p>
                <p className={'padding-left-10'}>{'productId: "sku-productId1",'}</p>
                <p className={'padding-left-10'}>{'level: "optional-level",'}</p>
                <p className={'padding-left-10'}>{'resourceIds: ["resourceId1", "resourceId2"],'}</p>
                <p>{'},'}</p>
                <p>...</p>
                <p>]</p>
            </ExpanderPanel>
        </div>
    );
};
